<template>
  <div class="form-group">
    <label for="SortOptions">{{T('Sort by')}}:</label>
    <select class="custom-select mr-auto" v-on:change="sendChangedSortFunction" v-model="selected">
      <option id="SortOptions" disabled value="">{{T('Please select one')}}</option>
      <option id="SortOptions" v-for="(o, idx) in options" v-bind:key="idx" v-bind:value="idx">{{ T(o) }}</option>
    </select>
  </div>
</template>

<script>
export default {
    props: {
        options: Array,
    },
    data: function() {
        return {
            selected: 0
        }
    },
    methods: {
        sendChangedSortFunction($event) {
            let idx = $event.target.value;
            this.$emit("sort-function-changed", {idx, value: this.options[idx]})
    }
  }
}
</script>
