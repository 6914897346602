<template>
<b-modal :id="id" :title="T(title)" :ok-title="T('Submit')" :cancel-title="T('Cancel')" @ok="onOK" @cancel="onCancel">
  <b-container>
    <b-row class="py-2">
      <b-col>
        <b-form-group
          label-for="description"
          :label="T('Description')"
          :description="T('Short description to identify this schedule')">
          <b-form-input id="description" v-model="description" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-2">
      <b-col>
        <b-form-group
          label-for="cardtype"
          :label="T('Card Type')">
          <b-form-select id="cardtype" v-model="cardType" :options="availableCardType" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-2">
      <b-col>
        <b-form-group
          :label="T('NFC Code')"
          label-for="nfc-code"
          :invalid-feedback="invalidFeedback"
          :state="validateState"
          :description="T('Scan the NFC tag using your mobile device and enter the code')">
          <b-form-input
            v-model="nfcCode"
            id="nfc-code"
            type="text" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="py-2">
      <b-col>
        <b-form-group
          :label="T('Mevia Code')"
          label-for="mevia"
          :invalid-feedback="invalidFeedback"
          :state="validateState"
          :description="T('Enter the code found on the back of the MEVIA device')">
          <b-form-input id="mevia" v-model="meviaCode" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</b-modal>
</template>
        
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Assign Card',
    },
    id: {
      default: 'assignCardDialog',
    },
  },
  data () {
    return {
      description: '',
      nfcCode: '',
      meviaCode: '',
      cardType: "MultiDose",
    };
  },
  computed: {
    validateState() {
       return !(this.meviaCode.length != 0 && this.nfcCode.length != 0);
    },
    invalidFeedback() {
      return this.T("You can only use the schedule on one platform (NFC platform is used by default)")
    },
    availableCardType() {
      return [
        {value: "MultiDose", text: "Multi Dose"},
        {value: "UnitDose", text: "Unit Dose"},     
      ]
    },
  },
  methods: {
    onOK(evt) {
      if (this.validateState) {
        if (this.meviaCode.length  != 0) {
          this.$emit('submit', {deviceType: 'mevia', cardType: this.cardType, code: this.meviaCode, description: this.description});
        } else {
          this.$emit('submit', {deviceType: 'nfc', cardType: this.cardType, code: this.nfcCode, description: this.description});
        }
        this.meviaCode = '';
        this.nfcCode = '';
        this.description = '';
      } else {
        evt.preventDefault();
      }
    },
    onCancel() {
      this.meviaCode = '';
      this.nfcCode = '';
      this.description = '';
    },
  },
}
</script>
