<template>
<div>
  <b-input-group>
    <template v-slot:prepend>
      <b-input-group-text><i class="fa fa-language"></i></b-input-group-text>
    </template>
    <b-form-select v-model="language" :options="availableLanguage"></b-form-select>
  </b-input-group>
</div>
</template>

<script>

import { mapState, mapMutations } from "vuex";
import { languageList } from '@/lib/translator';

export default {
  computed: {
    ...mapState(['language/selectedLanguage']),
    language: {
      get: function() {
        return this.selectedLanguage;
      },
      set: function(v) {
        this.setLanguage(v);
      },
    },
    availableLanguage: function() {
      return languageList;
    },
  },
  methods: {
    ...mapMutations(['language/setLanguage']),
  },
}
</script>
