<template>
  <div class="container">
        <b-table :items="patients"
                 :per-page="currentPageSize"
                 :current-page="currentPage"
                 :fields="fields" >
          <template v-slot:cell(Name)="data">
            <router-link class="patient-name" v-bind:to="{ name: 'patient', params: { pid: data.item.ID } }">
              {{ data.value | defaultValue('(n/a)') }} 
            </router-link>
          </template>
          <template v-slot:cell(StartDate)="data">
            {{ data.value | formatDate }}
          </template>
          <template v-slot:cell(button)="data">
            <b-btn class="ml-2" variant="danger" @click="onRemove(data.item)">{{T('Remove')}}</b-btn>
            <b-btn class="ml-2"
                   :variant="onoffVariant(data.item)"
                   @click="onToggleNotification(data.item)">
              {{T('Notification')}}
              <span v-if="data.item.AcceptNotification == true">{{T('On')}}</span>
              <span v-if="data.item.AcceptNotification == false">{{T('Off')}}</span>
            </b-btn>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1">
            <b-pagination :total-rows="totalRows" :per-page="currentPageSize" v-model="currentPage" class="my-0" />
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group horizontal :label="T('Per page')" class="mb-0">
              <b-form-select :options="pageSizeOptions" v-model="currentPageSize" />
            </b-form-group>
          </b-col>
        </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    patients: Array,
  },
  data () {
    return {
      currentPage: 1,
      pageSizeOptions: [1, 5, 10, 20, 25], 
      currentPageSize: 5,
      selectedPatient: {},
    }
  },
  computed: {
    fields() {
      return [
        { key: 'Name', label: this.T('Patient Reference') },
        { key: 'StartDate', label: this.T('Start at') },
        { key: 'button', label: ''},
      ];
    },
    totalRows() {
      return this.patients.length;
    },
  },
  methods: {
    ...mapActions('patients', ['storePatient', 'removePatient']),
    onRemove (patient) {
      this.$bvModal.msgBoxConfirm(this.T('Are you sure you would like to remove this patient?'), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
        if (value) {
          this.removePatient(patient);
        }
      });
    },
    onoffVariant(patient) {
      return patient.AcceptNotification ? "success" : "warning";
    },
    onToggleNotification(patient) {
      let p = Object.assign({}, patient)
      p.AcceptNotification = !patient.AcceptNotification;
      this.storePatient(p)
    },
  },
  watch: {
    patients: function(val, old) {
      if (val != old) {
        this.currentPage = 1;
      }
    }
  }
}
</script>

<style>
.patient-list-move {
    transition: transform .3s;
}
.patient-list-item {
    transition: all .3s ease;
}

.patient-list-enter-active, .patient-list-leave-active {
    opacity: 0;
}
.patient-name {
    color: black;
}
</style>
