<template>
  <form>
    <div class="form-group">
      <label class="" for="searchPatients">{{T('Search patients')}}</label>
      <div class="input-group">
        <input type="text" class="form-control" id="searchPatients" :placeholder="T('Patient Name')" v-on:input="sendChangeFilterTextEvent"/> 
        <div class="input-group-append">
          <span class="input-group-text">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  export default {
    methods: {
      sendChangeFilterTextEvent($event) {
        this.$emit('filter-text-changed', $event.target.value);
      }
    }
  }
</script>
