<template>
<b-form-invalid-feedback class="text-danger" :force-show="show">
  <div v-for="m in messages" v-bind:key="m">{{ T(m) }}</div>
</b-form-invalid-feedback>
</template>

<script>
export default {
  name: 'BFormValidationError',
  props: ["validation"],
  computed: {
    show() {
      return this.validation.$dirty && this.validation.$invalid
    },
    messages() {
      let messages = [];
      if (this.validation.$dirty) {
        if (this.hasValidationError("required")) {
          messages.push("Please enter a value");
        } else if (this.hasValidationError("email")) {
          messages.push("Please enter a valid email address");
        } else if (this.hasValidationError("phonenumber")) {
          messages.push("Please enter a valid phone number");
        } else if (this.hasValidationError("between")) {
          messages.push("Date should be within the current schedule start and end date");
        }
      }
      return messages;
    }
  },
  methods: {
    hasValidationError(type) {
      return this.validation.$params.hasOwnProperty(type) && !this.validation[type];
    }
  }
}
</script>
