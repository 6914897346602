<template>
<b-modal id="reportDateSelectorDialog" ref="reportDateSelectorModal" :title="T('Report Date Range Selection')"
         @ok="onOK" :ok-title="T('OK')" :cancel-title="T('Cancel')">
  <b-container>
    <b-row>
      <b-col>
        <b-form-group id="reportStartDate">
          <b-input-group :prepend="T('Report Start Date')">
            <date-picker class="form-control"
                         id="reportStartDate"
                         :lang="Language()"
                         v-model="startDate"
                         :placeholder="T('Select Start Date')" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group id="reportEndDate">
          <b-input-group :prepend="T('Report End Date')">
            <date-picker class="form-control"
                         id="reportEndDate"
                         :lang="Language()"
                         v-model="endDate"
                         :placeholder="T('Select End Date')" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { startOfDay } from '@/lib/doses';
import api from '@/api';

export default {
  components: { DatePicker },
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDate: startOfDay(new Date(), this.timezone).subtract(1, 'year'),
      endDate: startOfDay(new Date(), this.timezone),
    }
  },
  computed: {
    timezone: function() {
      return this.patient && this.patient.timezone;
    },
    patientid: function() {
      return this.patient && this.patient.ID
    },
  },
  methods: {
    onOK: function() {
      api.downloadPharmacistReport(this.patientid, this.startDate, this.endDate)
    },
  },
}
</script>
