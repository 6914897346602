<template>
<div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-5">
        <patient-list-filter v-on:filter-text-changed="onFilterTextChanged"/>
      </div>
      <div class="col-3 ml-auto">
        <patient-list-sort v-bind:options="sortFunction.map(x => x.label)" v-on:sort-function-changed="onSortFunctionChanged" />
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col ml-auto">
        <patient-list v-bind:patients="patients" />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PatientListFilter from "../components/PatientListFilter";
import PatientListSort from "../components/PatientListSort";
import PatientList from "../components/PatientList";

export default {
  components: { PatientListFilter, PatientListSort, PatientList },
  data: function() {
    return {
      filter: '',
      sortFunction: [{ label: 'Patient Name', cmp: (a, b) => { return String(a.Name).localeCompare(b.Name) }},
                     { label: 'Start Date', cmp: (a, b) => { return Date.parse(a.StartDate) - Date.parse(b.StartDate) }},
                     { label: 'Descending Start Date', cmp: (a, b) => { return Date.parse(b.StartDate) - Date.parse(a.StartDate) }}],
      comparer: null
    }
  },
  computed: {
    patients() {
      let cmp = (this.comparer == null) ? this.sortFunction[0].cmp : this.comparer;
      return this.filteredByReference(this.filter).sort(cmp);
    },
    ...mapGetters('patients', { filteredByReference: 'filteredByReference' }),
  },
  methods: {
    onFilterTextChanged($event) {
      this.filter = $event;
    },
    onSortFunctionChanged($event) {
      this.comparer = this.sortFunction[$event.idx].cmp;
    }
  },
  created() {
    this.$store.dispatch('patients/getAllPatients');
  }
}
</script>
