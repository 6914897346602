<template>
<b-modal :id="id" :title="T(title)" ref="dosetakendialog" :ok-title="T('Changed')" :cancel-title="T('Cancel')" @ok="onOk">
  <b-container>
    <b-row class="my-3">
      <b-form-group id="doseTakenDateFormGroup"
                    label-for="doseTakenDatePicker"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.date.$error">
        <b-input-group :prepend="T('Taken date')">
          <date-picker class="form-control"
                       id="doseTakenDatePicker"
                       :not-before="startDate"
                       :not-after="endDate"
                       lang="Language()"
                       v-model="$v.date.$model"
                       :placeholder="T('Select Date')" />
        </b-input-group>
      </b-form-group>
    </b-row>
    <b-row class="my-3">
      <b-form-group id="doseTakenTimeFormGroup"
                    label-for="doseTakenTimeFormInput"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.time.$error" >
        <b-input-group :prepend="T('Taken time')">
          <vue-timepicker id="doseTakenTimeFormInput" class="form-control" v-model="$v.time.$model" format="hh:mm a" />
        </b-input-group>
      </b-form-group>
    </b-row>
  </b-container>
</b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
import { required } from "vuelidate/lib/validators";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { toDateTime, toTime } from '@/lib/doses';

export default {
  components: { DatePicker, VueTimepicker },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "Dose change taken dialog",
    },
    taken: {
      default: () => moment.tz().format(),
    },
    startDate: {
      default: '',
    },
    endDate: {
      default: '',
    },
    timezone: {
      default: () => moment.tz.guess()
    },
  },
  data() {
    return {
      date: {},
      time: {},
    };
  },
  validations: {
    date: {
      between: function(value) {
        if (this.startDate == '' || this.endDate == '') {
          return true;
        }
        return moment(value).isBetween(this.startDate, this.endDate, 'day', '[]')
      }
    },
    time: {
      required,
    },
  },
  methods: {
    onOk: function() {
      let d = toDateTime(this.date, this.time, this.timezone)
      this.$emit("update", d);
      this.reset()
    },
    reset: function() {
      let d = moment.tz(this.timezone);
      this.date = d.toDate();
      this.time = toTime(d);
    },
  },
  watch: {
    taken: function(val) {
      if (val == "0001-01-01T00:00:00Z") {
        this.reset();
      } else {
        let d = moment.tz(val, this.timezone);
        this.date = d.toDate();
        this.time = toTime(d);
      }
    },
  },
}
</script>
