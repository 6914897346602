<template>
<b-container fluid>
  <b-row class="bg-menu" align-v="center">
    <b-col>
      <b-container>
        <b-row align-v="center">
          <b-col md="auto">
            <span class="patient-name">{{ patient && patient.Name | defaultValue('(n/a)') }}</span>
          </b-col>
          <b-col md="auto">
            <b-container class="adherence-avg text-center" :class="statusColor">{{T('Overall Adherence')}}: {{overallAdherence | round }}%</b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-col>
    <b-col cols="auto">
      <b-container>
        <b-row>
          <b-col md="auto" class="p-2">
            <b-btn variant="outline-primary" class="bg-white" v-b-modal.reportDateSelectorDialog>
              <i class="fa fa-sticky-note"></i>
              <span class="pl-1">{{T('Report')}}</span>
            </b-btn>
            <report-date-selector :patient="patient"/>
          </b-col>
          <b-col md="auto" class="p-2">
            <b-btn variant="outline-primary" class="bg-white" v-b-modal.patientSettingDialog>
              <i class="fa fa-cog"></i>
              <span class="pl-1">{{ T('Settings')}}</span>
            </b-btn>
            <settings id="patientSettingDialog"
                      v-model="patient"
                      @save-patient="onSavePatient"
                      @remove-patient="onRemovePatient" />
          </b-col>
          <b-col md="auto" class="p-2">
            <b-btn variant="outline-primary" class="bg-white" v-b-modal.newScheduleDateDialog>
              <i class="fas fa-calendar-plus"></i>
              <span class="pl-1">{{ T('New Schedule')}}</span>
            </b-btn>
            <schedule-date-editor id="newScheduleDateDialog"
                                  :timezone="patient.Timezone"
                                  v-model="schedule"
                                  @save="onSaveSchedule" />
          </b-col>
        </b-row>
      </b-container>
    </b-col>
  </b-row>
  <b-row class="pt-4">
    <b-col>
      <b-container fluid> 
        <b-row> 
          <schedule-list :patientID="pid"/>
        </b-row>
      </b-container>
    </b-col>
  </b-row>
</b-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Settings from '@/components/Patient/Settings';
import ScheduleList from '@/components/ScheduleList';
import ScheduleDateEditor from '@/components/ScheduleDateEditor';
import ReportDateSelector from '@/components/ReportDateSelector';

import { Schedule } from '@/models';

export default {
  components: { ScheduleDateEditor, Settings, ScheduleList, ReportDateSelector, },
  props: {
    pid: {
      required: true
    }
  },
  data: function() {
    return {
      patient: {},
      schedule: {},
    }
  },
  computed: {
    ...mapState('patients', ['selectedPatient', 'list', 'overallAdherence', 'reporturl']),
    statusColor: function() {
      if (this.overallAdherence > 80) {
        return { "status-good": true }  
      } else if (this.overallAdherence > 60) {
        return { "status-avg": true}
      } 
      return {"status-bad": true}
    },
  },
  methods: {
    ...mapMutations('patients', ['setSelectedPatient']),
    ...mapActions('patients', ['storePatient', 'removePatient', 'getAllPatients']),
    onSavePatient(patient) {
      this.storePatient(patient)
    },
    onRemovePatient(patient) {
      if (this.removePatient(patient)) {
        this.$router.push({name: 'patientList' });
      }
    },
    onSaveSchedule(schedule) {
      this.$store.dispatch('schedules/storeSchedule', schedule);
    },
  },
  created() {
    if (this.list.length == 0) {
      this.$store.dispatch('patients/getAllPatients').then(() => {
        this.setSelectedPatient(this.pid);
        this.patient = Object.assign({}, this.selectedPatient);
      });
    } else {
        this.setSelectedPatient(this.pid);
        this.patient = Object.assign({}, this.selectedPatient);
    }
    this.schedule = new Schedule(this.pid);
    this.$store.dispatch('patients/getOverallAdherence', this.pid);
    this.$store.dispatch('patients/getReportHash', this.pid);
  },
  watch: {
    list: function() {
      this.setSelectedPatient(this.pid);
    },
    selectedPatient: function(val) {
      this.patient = Object.assign({}, val);
    },
  },
  filters: {
    round: function(value) {
      if (!isNaN(parseFloat(value)) && isFinite(value))
        return Math.round(value * 100) / 100.0
      return '';
    },
  },
}
</script>

<style lang="scss">
  @import '@/themes/branding.scss';

  .adherence-avg {
    box-shadow: 2px;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  .status-good {
    background-color: theme-color("good-adherence");
  }
  .status-avg{
    background-color: theme-color("avg-adherence");
  }
  .status-bad{
    background-color: theme-color("bad-adherence");
  }

</style>
