<template>
<b-modal :id="id" :ok-title="T('Save')" :cancel-title="T('Cancel')" @ok="onSave" @cancel="onCancel" ref="addPatientModal" size="lg">
  <div slot="modal-header">
    <h5 class="modal-title" id="addPatientDialogLabel">
      <i class="fa fa-user-plus"></i>
      {{ T('Add Patient') }}
    </h5>
  </div>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group label-cols-sm="3"
                      label-align="right"
                      label-for="patientReference"
                      :label="T('Patient Reference:')"
                      :invalid-feedback="T('this field is required')"
                      :state="!$v.patient.Name.$error">
          <b-form-input id="patientReference" v-model="$v.patient.Name.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols-sm="3"
                      label-align="right"
                      label-for="patientLanguage"
                      :label="T('Patient Language:')"
                      :invalid-feedback="T('this field is required')"
                      :state="!$v.patient.Language.$error">
          <b-form-select id="patientLanguage" v-model="$v.patient.Language.$model" :options="availableLanguage" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col> 
        <b-form-group label-cols="3"
                      label-align="right"
                      label-for="patientStartDate"
                      :label="T('Start Date:')"
                      :invalid-feedback="T('invalid start date')"
                      :state="!$v.patient.StartDate.$error">
          <date-picker class="form-control" id="patientStartDate" v-model="$v.patient.StartDate.$model" :lang="Language()" :placeholder="T('Select Date')"></date-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col >
        <b-form-group label-cols="3">
          <b-form-checkbox switch v-model="$v.patient.AcceptNotification.$model" name="check-button">
            {{ T('Notification') }}
            <span v-if="patient.AcceptNotification == true">{{ T('On') }}</span>
            <span v-if="patient.AcceptNotification == false">{{ T('Off') }}</span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="3"
                      label-align="right"
                      label-for="patientEmail"
                      :label="T('Email:')"
                      :invalid-feedback="T('invalid email')"
                      :state="!$v.patient.Email.$error">
          <b-form-input type="email" id="patientEmail" v-model="$v.patient.Email.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-cols="3"
          label-align="right"
          label-for="patientPhone"
          :label="T('Phone:')"
          :invalid-feedback="T('invalid phone number')"
          :state="!$v.patient.Phone.$error">
          <vue-phone-number-input id="patientPhone" @update="onUpdatePhone" v-model="phone" :translations="phoneNumberInputTranslations()" />

        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-cols="3"
          label-align="right"
          label-for="patientTz"
          :label="T('Timezone:')"
          :invalid-feedback="T('invalid timezone')"
          :state="!$v.patient.Timezone.$error">
          <b-form-input id="patientTz" type="text" v-model="$v.patient.Timezone.$model" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label-cols="3">
          <b-form-select @change="onTzSelected" :options="allTimezone" :select-size="4"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-cols="3"
          label-align="right"
          label-for="defaultNotificationMessageType"
          :label="T('Default Notification Message')" >
          <b-form-select v-model="selectedMessageType" :options="notificationMessageType">
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</b-modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { phonenumber } from '@/validators';
import DatePicker from 'vue2-datepicker';

import { mapActions, mapGetters } from 'vuex';
import { getAvailableTimeZone } from '@/lib/timezone';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { addUserNotification, addNotification, getNotficationType } from "@/lib/notification";
import { languageList } from '@/lib/translator';

function defaultPatient() {
  return {"AcceptNotification": true};
}

export default {
  components: { VuePhoneNumberInput, DatePicker },
  props: {
    id: {
      default: "addPatientDialog",
    },
  },
  data: function() {
    return {
      patient: defaultPatient(),
      selectedMessageType: getNotficationType()[0],
      phone: "",
    }
  },
  validations: {
    patient: {
      Name: {
        required
      },
      Language: {
        required
      },
      StartDate: {
        required
      },
      AcceptNotification: {
        required
      },
      Phone: {
        phonenumber
      },
      Email: {
        email
      },
      Timezone: {
        required
      },
    },
  },
  computed: {
    ...mapGetters({user: 'getCurrentUser'}),
    notificationMessageType() {
      return getNotficationType().map(x => { return {value: x, text: this.T(x)}; });
    },
    allTimezone() {
      return getAvailableTimeZone()
    },
    availableLanguage() {
      return languageList;
    }
  },
  methods: {
    ...mapActions('patients', {newPatient: 'newPatient'}),
    onSave() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.selectedMessageType === "Monitoring Only") {
          addUserNotification(this.patient, this.selectedMessageType, this.user, this.Language);
        } else {
          addNotification(this.patient, this.selectedMessageType);
        }
        let p = this.newPatient(Object.assign({}, this.patient));
        if (p != null) {
          this.patient = defaultPatient()
          this.phone = "";
          this.$v.$reset();
          this.$bvModal.hide(this.id);
          this.$router.push({ name: 'patientList', params: { pid: p.ID }}); 
        }
      }
    },
    onCancel() {
      this.patient = defaultPatient()
      this.$v.$reset();
    },
    onUpdatePhone(phone) {
      this.$v.patient.Phone.$model = phone.formattedNumber;
    },
    onTzSelected(val) {
      this.patient = Object.assign({}, this.patient, {"Timezone": val});
    },
  },
}
</script>

<style>
.mx-input-append {
    padding: 0px;
}

.mx-datepicker {
    display: block;
    line-height: 1.5;
}

.mx-input {
    padding: 0px;
    border: none;
    box-shadow: none;
    height: 24px;
}
</style>
