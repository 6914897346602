<template>
<b-card v-show="show" :title="title" class="dialog">
  <b-card-text>
  <b-container fluid class="body">
    <b-row>
      <b-col>
        <b-form-group
          horizontal
          :label="T('Offset')"
          label-for="reminderOffset">
          <b-form-input type="text"
                        id="reminderOffset"
                        v-model="$v.reminder.Offset.$model" />
          <b-form-validation-error :validation="$v.reminder.Offset" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          horizontal
          :label="T('Message')"
          label-for="reminderMessage">
          <b-form-textarea rows="4"
                           max-rows="6"
                           id="reminderMessage"
                           v-model="$v.reminder.Message.$model" />
          <b-form-validation-error :validation="$v.reminder.Message" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
  </b-card-text>
  <template #footer>
    <b-row align-h="end" >
      <b-col md="auto">
        <b-btn class="m-2" @click="onCancel" variant="secondary" >{{T('Cancel')}}</b-btn>
        <b-btn class="m-2" @click="onOk" variant="primary" >{{T('Ok')}}</b-btn>
      </b-col>
    </b-row>
  </template>
</b-card>
</template>

<script>
import { required, integer} from 'vuelidate/lib/validators';
import { Reminder } from '../../models';
import BFormValidationError from '../BFormValidationError';

export default {
  components: { BFormValidationError },
  name: 'editReminder',
  props: {
    title: {
      default: 'Edit Reminder',
    },
    show: {
      default: false,
    },
    value: {
      default: new Reminder(),
    },
  },
  data() {
    return {
      reminder: Object.assign({}, this.value),
    };
  },
  validations: {
    reminder: {
      Offset: {
        required,
        integer 
      },
      Message: {
        required
      },
    },
  },
  methods: {
    onOk() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.reminder.Offset = parseInt(this.reminder.Offset);
        this.reminder.MessageType = "custom";
        this.$emit('input', this.reminder);
        this.$emit('ok', this.reminder);
      }
    },
    onCancel() {
      this.$v.$reset();
      this.$emit('cancel');
    },
  },
  watch: {
    value(val) {
      this.reminder = Object.assign({}, val);
    },
  },
}
</script>

<style lang="scss">
.dialog {
    max-height: 410px;
    .body {
        height: 250px;
        overflow-y: scroll
    }
}
            
</style>


