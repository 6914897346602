<template>
<b-modal id="videoPlayerDialog"
         no-close-on-backdrop
         hide-header
         ok-only
         ok-title="Close">
  <b-embed
    type="iframe"
    aspect="16by9"
    :src="url"
    allowfullscreen
  ></b-embed>
</b-modal>
</template>

<script>
export default {
  props: {
    videourls: {
      required: true,
    },
  },
  computed: {
    url() {
      if (this.videourls && (this.videourls.length > 0)) {
        let code = this.videourls[0];
        return `https://www.youtube.com/embed/${code}?rel=0`
      } else {
        return undefined;
      }
    },
  },    
}
</script>
