<template>
<b-card v-show="show" :title="title" class="dialog">
  <b-card-text>
  <b-container fluid class="body">
      <b-col md="auto">
        <b-form-group
          :label="T('Name')"
          label-for="recipientName"
          :invalid-feedback="T('this field is required')"
          :state="!$v.recipient.Name.$error">
          <b-form-input id="recipientName" v-model="$v.recipient.Name.$model" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="T('Phone number')"
          label-for="recipientPhone"
          :invalid-feedback="T('invalid phone number')"
          :state="!$v.recipient.Phone.$error">
          <vue-phone-number-input id="recipientPhone" @update="onUpdatePhone" v-model="phone" :translations="phoneNumberInputTranslations()" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="T('Email address')"
          label-for="recipientEmail"
          :invalid-feedback="T('invalid email')"
          :state="!$v.recipient.Email.$error">
          <b-form-input id="recipientEmail" v-model="$v.recipient.Email.$model" />
        </b-form-group>
      </b-col>
  </b-container>
  </b-card-text>
  <template #footer>
    <b-row align-h="end" >
      <b-col md="auto">
        <b-btn class="m-2" @click="onCancel" variant="secondary" >{{T('Cancel')}}</b-btn>
        <b-btn class="m-2" @click="onOk" variant="primary" >{{T('Ok')}}</b-btn>
      </b-col>
    </b-row>
    </template>
</b-card>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { phonenumber } from '../../validators';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  components: { VuePhoneNumberInput },
  name: 'editRecipient',
  props: {
    title: {
      default: 'Edit Recipient',
    },
    show: {
      default: false,
    },
    value: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      recipient: Object.assign({}, this.value),
      phone: this.value.Phone,
    }
  },
  validations: {
    recipient: {
      Name: {
        required
      },
      Phone: {
        phonenumber,
      },
      Email: {
        email,
      },
    },
  },
  methods: {
    onOk() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let r = this.recipient;
        this.$emit('input', r);
        this.$emit('ok', r);
      }
    },
    onUpdatePhone(phone) {
      this.$v.recipient.Phone.$model = phone.formattedNumber;
    },
    onCancel() {
      this.$v.$reset();
      this.$emit('cancel');
    },
  },
  watch: {
    value(val) {
      this.recipient = Object.assign({}, val);
      this.phone = this.recipient.Phone;
    },
  },
}

</script>

<style lang="scss">
.dialog {
    max-height: 410px;
    .body {
        height: 250px;
        overflow-y: scroll
    }
}
            
</style>

