<template>
<b-modal :id="id" :title="T(title)" ref="doseScheduleModal" :ok-title="T('Save')" :cancel-title="T('Cancel')" @ok="onOk" size="lg">
  <div slot="modal-header">
    <h5 class="modal-title" id="addDoseSchedule">
      <i class="fas fa-file-prescription"></i>
      <span class="pl-1">{{ T('Dose Scheduler') }}</span>
    </h5>
  </div>
  <b-container>
    <b-row>
      <b-tabs pills vertical v-model="currentTabIdx">
        <b-tab :title="T('Repeated')" active>
          <b-container>
            <b-row class="my-4">
              <b-col>
                <h4>{{ T('Schedule daily doses') }}</h4>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="dailyScheduleStartDate"
                              :state="!$v.localStartDate.$error">
                  <b-input-group :prepend="T('Starting')">
                    <date-picker class="form-control"
                                 :first-day-of-week="1"
                                 :not-before="startDate"
                                 :not-after="endDate"
                                 id="dailyScheduleStartDatePicker"
                                 :lang="Language()"
                                 v-model="$v.localStartDate.$model"
                                 :placeholder="T('Select Date')" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="dailyScheduleEndDate"
                              :state="!$v.localEndDate.$error">
                  <b-input-group :prepend="T('Until')">
                    <date-picker class="form-control"
                                 id="dailyScheduleEndDatePicker"
                                 :first-day-of-week="1"
                                 :not-before="startDate"
                                 :not-after="endDate"
                                 :lang="Language()"
                                 v-model="$v.localEndDate.$model"
                                 :placeholder="T('Select Date')" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="dailyScheduleTime">
                  <b-input-group :prepend="T('Schedule a dose for:')">
                    <vue-timepicker class="form-control"
                                    v-model="doseTime"
                                    format="hh:mm a" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="dailyDoseIntakeRange">
                  <b-input-group :prepend="T('Intake Range')" :append="T('hours')">
                    <b-form-input type="number" min="0" max="12" v-model="$v.localRange.$model"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab :title="T('Single Dose')">
          <b-container>
            <b-row class="my-4">
              <b-col>
                <h4>{{ T('Schedule doses') }}</h4>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="singleScheduleDate" >
                  <b-input-group :prepend="T('Date to Schedule dose')">
                    <date-picker class="form-control"
                                 id="singleScheduleDate"
                                 :first-day-of-week="1"
                                 :lang="Language()"
                                 :not-before="startDate"
                                 :not-after="endDate"
                                 v-model="$v.localStartDate.$model"
                                 :placeholder="T('Select Date')" />
                  </b-input-group>
                </b-form-group>
              </b-col> 
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="singleScheduleDate" >
                  <b-input-group :prepend="T('Schedule a dose for:')">
                    <vue-timepicker class="form-control"
                                    v-model="doseTime"
                                    format="hh:mm a" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="singleDoseIntakeRange">
                  <b-input-group :prepend="T('Intake Range')" :append="T('hours')">
                    <b-form-input type="number" min="0" max="12" v-model="$v.localRange.$model"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab :title="T('Weekly Schedule')">
          <b-container>
            <b-row class="my-4">
              <b-col>
                <h4>{{ T('Schedule doses') }}</h4>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="customScheduleStartDate">
                  <b-input-group :prepend="T('Starting')">
                    <date-picker class="form-control"
                                 id="customScheduleStartDate"
                                 :first-day-of-week="1"
                                 :not-before="startDate"
                                 :not-after="endDate"
                                 :lang="Language()"
                                 v-model="$v.localStartDate.$model"
                                 :placeholder="T('Select Date')" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="customScheduleEndDate">
                  <b-input-group :prepend="T('Until')">
                    <date-picker class="form-control"
                                 id="customScheduleEndDate"
                                 :first-day-of-week="1"
                                 :not-before="startDate"
                                 :not-after="endDate"
                                 :lang="Language()"
                                 v-model="$v.localEndDate.$model"
                                 :placeholder="T('Select Date')" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3"> 
              <b-col>
                <b-input-group :prepend="T('Schedule a dose for:')">
                  <vue-timepicker class="form-control"
                                  v-model="doseTime"
                                  format="hh:mm a" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-checkbox-group v-model="days" name="weekdays" :options="weekdays" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-3">
              <b-col>
                <b-form-group id="customDoseIntakeRange">
                  <b-input-group :prepend="T('Intake Range')" :append="T('hours')">
                    <b-form-input type="number" min="0" max="12" v-model="$v.localRange.$model"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs> 
    </b-row>
  </b-container>
</b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css';
import moment from 'moment-timezone'
import { required } from "vuelidate/lib/validators";
import {generateCustomDoses, generateRepeatedDoses, generateSingleDose } from '@/lib/doses';

export default {
  components: { DatePicker, VueTimepicker },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "Dose Schedule Editor"
    },
    startDate: {
      default: '',
    },
    endDate: {
      default: '',
    },
    timezone: {
      default: () => moment.tz.guess()
    },
  },
  data() {
    return {
      doseTime: {hh: "12", mm: "00", a: "am"},
      currentTabIdx: 1,
      localStartDate: this.startDate,
      localEndDate: this.endDate,
      localRange: 1,
      days: [],
    };
  },
  validations: {
    localStartDate: {
      between: function(value) {
        if (this.startDate == '' || this.endDate == '') {
          return true;
        }
        return moment(value).isBetween(this.startDate, this.endDate, 'day', '[]')
      }
    },
    localEndDate: {
      between: function(value) {
        if (this.startDate == '' || this.endDate == '') {
          return true;
        }
        return moment(value).isBetween(this.startDate, this.endDate, 'day', '[]')
      }
    },
    localRange: {
      required,
    },
  },
  computed: {
    weekdays() {
      return Array.from(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'], x => this.T(x));
    },
  },
  methods: {
    onOk(evt) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let range = Number(this.localRange)
        let events = []
        switch (this.currentTabIdx) {
        case 0:
          events = generateRepeatedDoses(this.localStartDate, this.localEndDate, this.doseTime, this.timezone, range);
          break;
        case 1:
          events = generateSingleDose(this.localStartDate, this.doseTime, this.timezone, range);
          break;
        case 2:
          events = generateCustomDoses(this.localStartDate, this.localEndDate, this.doseTime, this.days, this.timezone, range);
          break;
        }
        this.$bvModal.hide(this.id);
        this.$emit("save", events);
      } else {
        evt.preventDefault();
      }
    },
  },
  watch: {
    startDate: function(val) {
      this.localStartDate = moment(val);
    },
    endDate: function(val) {
      this.localEndDate = moment(val);
    },
  }
}
</script>

<style>

.mx-datepicker, form-control {
    height: auto;
} 

.time-picker
input.display-time {
    border: none;
    height: auto;
    padding: 0;
    width: 100%;
    min-width: 50px;
}
</style>
