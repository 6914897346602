<template>
  <b-modal :id="id" :title="T(title)" ok-title="Save" @ok="onOk">
    <b-container>
      <b-row class="py-2">
        <b-col>
          <b-form-group
            label-for="url"
            :label="T('Youtube Code')"
            :description="T('ex: for url https://www.youtube.com/watch?v=ri74QCJW1rQ the video code is ri74QCJW1rQ')"
            :state="validateState"
            :invalid-feedback="T('Invalid youtube video code')" >
            <b-form-input id="url" v-model="videourl" type="text" placeholder="ri74QCJW1rQ" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
export default {
  model: {
    prop: 'schedule',
    event: 'input',
  },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "Youtube Video Code Editor",
    },
    schedule: {
      type: Object,
    },
  },
  data () {
    return {
      videourl: "",
    };
  },
  computed: {
    validateState() {
      return (this.videourl.length > 0 && !this.videourl.startsWith("http"))
    },
  },
  methods: {
    onOk(evt) {
      if (this.validateState) {
        this.$emit('save', this.videourl);
        this.videourl;
      } else {
        evt.preventDefault();
      }
    },
  },
  watch: {
    schedule: function(val) {
      if (val.VideoURLs && val.VideoURLs.length > 0) {
        this.videourl = val.VideoURLs[0];
      } else {
        this.videourl = "";
      }
    },
  },
}
</script>
