<template>
  
<b-container fluid class="branding-header">
  <b-navbar>
    <b-navbar-brand href="#">
      <img src="@/assets/logo-branding.svg" class="logo d-inline-block" alt="" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-navbar-nav>
      <b-btn variant="outline-primary" class="mr-2" @click="onViewPatients">
        <i class="fa fa-list"></i>
        <span class="pl-1">{{ T('View Patients') }}</span>
      </b-btn>
      <b-btn variant="outline-primary" class="mr-2" v-b-modal.addPatientDialog >
        <i class="fa fa-user-plus"></i>
        <span class="pl-1">{{ T('Add Patient') }}</span>
      </b-btn>
      <b-btn variant="outline-primary" class="mr-2" v-b-modal.anomalyReportDialog >
        <i class="fa fa-receipt"></i>
        <span class="pl-1">{{ T('Missed Dose Report')}}</span>
      </b-btn>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-btn variant="outline-primary" class="mr-2 nav-link" v-b-modal.changePasswordDialog >
        <i class="fa fa-key"></i>
        <span class="pl-1">{{ T('Change Password') }}</span>
      </b-btn>
      <b-btn variant="outline-primary" class="mr-2 nav-link" @click="onLogout">
        <i class="fas fa-sign-out-alt"></i>
        <span class="pl-1">{{ T('Logout') }}</span>
      </b-btn>
    </b-navbar-nav> 
  </b-navbar>
<add-patient-dialog id="addPatientDialog" />
<anomaly-report id="anomalyReportDialog" />
<change-password />
</b-container>
</template>

<script>
import AddPatientDialog from './AddPatientDialog';
import ChangePassword from './ChangePassword';
import AnomalyReport from './AnomalyReport.vue';
import { mapActions } from "vuex";

export default {
  props: ['page'],
  components: { AddPatientDialog, ChangePassword, AnomalyReport },
  methods: {
    ...mapActions(["logout"]),
    onViewPatients: function() {
      this.$router.push({name: 'patientList'});
    },
    onLogout: function() {
      this.$bvModal.msgBoxConfirm(this.T('Are you sure you want to logout?'), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
          if (value) {
            this.logout();
            this.$router.push({name: 'login'});
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@/themes/branding.scss';

.logo {
    width: 200px;
}

</style>
