<template>
<div v-if="show" class="text-danger">
  <div v-for="m in messages" v-bind:key="m">{{ T(m) }}</div>
</div>
</template>

<script>
  export default {
    props: ["validation"],
    computed: {
      show() {
        return this.validation.$dirty && this.validation.$invalid
      },
      messages() {
        let messages = [];
        if (this.validation.$dirty) {
          if (this.hasValidationError("required")) {
            messages.push("Please enter a value")
          } else if (this.hasValidationError("email")) {
            messages.push("Please enter a valid email address");
          } else if (this.hasValidationError("phonenumber")) {
            messages.push("Please enter a valid phone number");
          } else if (this.hasValidationError("match")) {
            messages.push("password don't match");
          } else if (this.hasValidationError("between")) {
            messages.push("The selected date is not between the schedule start and end dates");
          }
        }
        return messages;
      }
    },
    methods: {
      hasValidationError(type) {
        return this.validation.$params.hasOwnProperty(type) && !this.validation[type];
      }
    }
  }
</script>
