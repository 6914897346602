<template>
<b-container>
  <edit-recipient :show="showRecipientDialog"
                  :title="T('Edit Recipient')"
                  v-model="recipient"
                  :ok-title="T('OK')"
                  :cancel-title="T('Cancel')"
                  @ok="onSaveRecipient"
                  @cancel="showRecipientDialog = false" />
  <b-container v-show="!showRecipientDialog">
    <b-row>
      <b-col>
        <b-card no-body class="dialog">
          <b-card-text>
            <b-container class="body">
            <b-table :items="recipients"
                     :per-page="perPage"
                     :current-page="currentPage"
                     :fields="fields" >

          <template v-slot:cell(Name)="data">
            <span class="align-baseline">{{ data.item.Name | defaultValue('(n/a)') }}</span>
          </template>
          <template v-slot:cell(Communication)="data">
            <div class="mx-3 align-baseline nowrap" v-if="data.item.Email"><b>{{T('Email')}}:</b> {{ data.item.Email }}</div>
            <div class="mx-3 align-baseline nowrap" v-if="data.item.Phone"><b>{{T('Phone')}}:</b> {{ data.item.Phone }}</div>
          </template>
          <template v-slot:cell(Buttons)="data">
          <b-button-group>
            <b-btn @click="onEditRecipient(data.item)" >{{T('Edit')}}</b-btn>
            <b-btn variant="danger" @click="onRemoveRecipient(data.item)">{{T('Remove')}}</b-btn>
          </b-button-group>
          </template>
        </b-table>
            </b-container>
        </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-1">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
      <b-col md="auto" class="my-1">
        <b-btn class="mx-2" @click="onEditNewRecipient">{{T('Add Recipient')}}</b-btn>
      </b-col>
    </b-row>
  </b-container>
</b-container>
</template>

<script>
import Vue from "vue";
import EditRecipient from './EditRecipient';
import { Recipient } from '../../models';

export default {
  components: { EditRecipient },
  props: {
    value: {
      required: true,
    },
  },
  data () {
    return {
      recipient: {},
      selectedIndex: -1,
      showRecipientDialog: false,
      currentPage: 1,
      perPage: 5,
    }; 
  },
  computed: {
    totalRows() {
      return this.recipients.length;
    },
    recipients() {
      let r = [];
      if (this.value && Array.isArray(this.value.Recipients)) {
        r.push(...this.value.Recipients);
      }
      return r;
    },
      fields() {
        return [
        { key: 'Name', label: this.T('Name') },
        { key: 'Communication', label: this.T('Communication') },
        { key: 'Buttons', label: ''}
      ];
    },
  },
  methods: {
    onEditNewRecipient() {
      this.onEditRecipient(new Recipient());
    },
    onEditRecipient(recipient) {
      this.selectedIndex = this.recipients.indexOf(recipient);
      this.recipient = recipient; //Object.assign({}, recipient);
      this.showRecipientDialog = true;
    },
    onRemoveRecipient(recipient) {
      this.$bvModal.msgBoxConfirm(this.T('Are you sure you would like to remove this recipient?'), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
        if (value) {
          let idx = this.recipients.indexOf(recipient);
          if (idx > -1) {
            this.recipients.splice(idx, 1);
            let p = Object.assign({}, this.value, {Recipients: this.recipients});
            this.$emit('input', p);
          }
        }  
      });
    },
    onSaveRecipient(recipient) {
      if (this.selectedIndex > -1) {
        Vue.set(this.recipients, this.selectedIndex, recipient);
      } else {
        this.recipients.unshift(recipient);
      }
      let p = Object.assign({}, this.value, {Recipients: this.recipients});
      this.$emit('input', p);
      this.showRecipientDialog = false;
    },
  },
  watch: {
    recipients(val) {
      if (val.length < this.currentPage * this.perPage) {
        this.currentPage = Math.round(val.length / this.perPage);
      }
    },
  },
}
</script>

<style lang="scss">
.dialog {
    max-height: 410px;
    .body {
    height: 250px;
    overflow-y: scroll
    }
}
div.nowrap {
 white-space: nowrap;
}
</style>
