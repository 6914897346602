<template>
<div>
<b-modal :id="id" :title="T(title)" ref="doseEventEditorModal" @shown="onShown">
  <b-container>
    <b-row class="my-4">
      <h4>{{ T('Schedule doses') }}</h4>
    </b-row>
    <b-row class="my-3">
      <b-col>
      <b-form-group id="doseIntakeLabelFormGroup"
                    label-for="doseIntakeLabelFormInput"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.intake.label.$error" >
          <b-input-group :prepend="T('Label')">
            <b-form-input v-model="$v.intake.label.$model"
                          id="doseIntakeLabelFormInput"
                          :placeholder="T('Dose intake')" />
          </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col>
      <b-form-group id="doseIntakeDateFormGroup"
                    label-for="doseIntakeDatePicker"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.intake.date.$error">
        <b-input-group :prepend="T('Intake date')">
          <date-picker class="form-control"
                       id="doseIntakeDatePicker"
                       :not-before="startDate"
                       :not-after="endDate"
                       lang="Language()"
                       v-model="$v.intake.date.$model"
                       :placeholder="T('Select Date')" />
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col>
      <b-form-group id="doseIntakeTimeFormGroup"
                    label-for="doseIntakeTimeFormInput"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.intake.time.$error" >
        <b-input-group :prepend="T('Intake time')">
          <vue-timepicker id="doseIntakeTimeFormInput" class="form-control" v-model="$v.intake.time.$model" format="hh:mm a" />
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
      <b-form-group id="doseIntakeRange"
                    label-for="doseIntakeRangeInput"
                    :invalid-feedback="T('this field is required')"
                    :state="!$v.intake.range.$error">
        <b-input-group :prepend="T('Intake Range')" :append="T('hours')">
          <b-form-input type="number" min="0" max="12" v-model="$v.intake.range.$model"></b-form-input>
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn variant="danger" @click="onRemove">{{ T('Remove') }}</b-btn>
      </b-col>
      <b-col cols="auto">
        <b-btn variant="info" v-b-modal.doseTakenDialog>{{ T('Change Taken Value') }}</b-btn>
      </b-col>
    </b-row>
  </b-container>
  <template v-slot:modal-footer>
    <b-btn variant="secondary" @click="onCancel()">{{ T('Cancel') }}</b-btn>
    <b-btn variant="primary" @click="onMultiSave()">{{ T('Multi Save') }}</b-btn>
    <b-btn variant="primary" @click="onSave()">{{ T('Save') }}</b-btn>
  </template>
</b-modal>
<dose-taken-dialog id="doseTakenDialog"
                   :taken="dose && dose.Taken"
                   :startDate="startDate"
                   :endDate="endDate"
                   :timezone="timezone"
                   @update="onTakenDoseUpdate" />

</div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
import { required } from "vuelidate/lib/validators";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import { toDateTime } from '@/lib/doses';
import DoseTakenDialog from '@/components/DoseTakenDialog';

export default {
  components: { DatePicker, VueTimepicker, DoseTakenDialog },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "Dose Event Editor",
    },
    dose: {
      type: Object,
    },
    startDate: {
      default: '',
    },
    endDate: {
      default: '',
    },
    timezone: {
      default: () => moment.tz.guess()
    }
  },
  data() {
    return {
      intake: {
        label: "",
        date: {},
        time: {},
        range: 0,
      },
      taken: undefined,
    };
  },
  validations: {
    intake: {
      label: {
        required,
      },
      date: {
        between: function(value) {
          if (this.startDate == '' || this.endDate == '') {
            return true;
          }
          return moment(value).isBetween(this.startDate, this.endDate, 'day', '[]')
        }
      },
      time: {
        required,
      },
      range: {
        required,
      },
    },
  },
  computed: {
    Dose() {
      let d = toDateTime(this.intake.date, this.intake.time, this.timezone)
      let o = Object.assign({}, this.dose, {Intake: d.toDate(), Title: this.intake.label, Range: Number(this.intake.range)})
      if (this.taken) {
        o.Taken = this.taken
        o.Status = "Open"
      } 
      return o
    },
  },
  methods: {
    onSave: function() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("save", this.Dose);
        this.reset()
        this.$bvModal.hide(this.id);
      }
    },
    onMultiSave: function() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit("multisave", this.Dose)
        this.reset()
        this.$bvModal.hide(this.id);
      }
    },
    onCancel: function() {
      this.reset();
      this.$bvModal.hide(this.id);
    },
    reset: function() {
      this.taken = undefined;
      this.intake.date = {};
      this.intake.time = {hh: 0, mm: 0, a: 'am'};
      this.intake.range = 0;
    },
    onRemove: function() {
      this.$emit("remove");
    },
    onTakenDoseUpdate: function(d) {
      this.taken = d
      this.$bvModal.hide('doseTakenDialog');
    },
    onShown: function() {
      let val = this.dose
      let d = moment.tz(val.Intake, this.timezone)
      this.intake.label = val.Title;
      this.intake.date = d.toDate();
      this.intake.time = {
        hh: d.format('hh'),
        mm: d.format('mm'),
        a: d.format('a'),
      };
      this.intake.range = val.Range;
    }
  },
}
</script>
<style>
.mx-input {
  border: 0 none white;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
