<template>
<b-modal :id="id" :title="title" :ok-title="T('Save')" :cancel-title="T('Cancel')" @ok="onOk">
  <b-container>
    <b-row>
      <h4>{{T('Select Schedule Date Range')}}</h4>
    </b-row>
    <b-row class="my-4">
      <b-input-group :prepend="T('Start Date')">
        <date-picker class="form-control"
                     id="scheduleStartDate"
                     :first-day-of-week="1"
                     :lang="Language()"
                     v-model="startDate"
                     :placeholder="T('Select Date')" />
      </b-input-group>
    </b-row>
    <b-row class="my-4">
      <b-input-group :prepend="T('End Date')">
        <date-picker class="form-control"
                     id="scheduleEndDate"
                     :first-day-of-week="1"
                     :lang="Language()"
                     v-model="endDate"
                     :placeholder="T('Select Date')" />
      </b-input-group>
    </b-row>
  </b-container>
</b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { startOfDay } from '@/lib/doses';

export default {
  components: { DatePicker },
  model: {
    prop: 'schedule',
    event: 'input',
  },
  props: {
    id: {
      required: true,
    },
    title: {
      default: "New Schedule",
    },
    schedule: {
      type: Object
    },
    timezone: {
      default: undefined,
    },
  },
  data () {
    return {
      startDate: startOfDay(new Date(), this.timezone),
      endDate: startOfDay(new Date(), this.timezone),
    };
  },
  methods: {
    onOk() {
      let s = Object.assign({}, this.schedule)
      s.StartDate = this.startDate;
      s.EndDate = this.endDate;
      this.$emit('input', s);
      this.$emit('save', s);
    },
  },
  watch: {
    schedule: function(val) {
      this.startDate= val.StartDate;
      this.endDate = val.EndDate;
    },
  },
}
</script>
<style>
.mx-input {
  border: 0 none white;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
