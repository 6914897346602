<template>
<div>
  <b-modal id="languageChangeConfirmation" no-close-on-backdrop hide-header ref="languageChangeConfirmationDialog" @hide="languageChangeConfirmationHandler">
    <p>{{T("Would you also like to switch reminder to ")}} {{T(languageString)}}</p>
    <template #modal-footer="{ cancel, hide }">
      <b-button size="sm" variant="primary" @click="hide('Yes')">{{T('Yes')}}</b-button>
      <b-button size="sm" variant="outline-primary" @click="hide('No')">{{T('No')}}</b-button>
      <b-button size="sm" variant="secondary" @click="cancel()">{{T('Cancel')}}</b-button>
    </template>
  </b-modal>
  <b-modal :id="id" no-close-on-backdrop ref="patientSettingDialog" :title="T('Patient Settings')" :ok-title="T('Save')" :cancel-title="T('Close')" size='lg' >
    <b-card no-body class="dialog">
      <b-tabs card>
        <b-tab :title="T('General')" active>
          <b-container>
            <b-row>
              <b-col>
                <b-form-group
                  label-cols="3"
                  label-align="right"
                  label-for="patientReference"
                  :label="T('Patient Reference:')"
                  :invalid-feedback="T('this field is required')"
                  :state="!$v.patient.Name.$error">
                  <b-form-input type="text" id="patientReference" v-model="$v.patient.Name.$model" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-cols="3"
                              label-align="right"
                              label-for="patientLanguage"
                              :label="T('Patient Language:')"
                              :invalid-feedback="T('this field is required')"
                              :state="!$v.patient.Language.$error">
                  <b-form-select id="patientLanguage" v-model="$v.patient.Language.$model" :options="availableLanguage" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col> 
                <b-form-group
                  label-cols="3"
                  label-for="patientStartDate"
                  label-align="right"
                  :label="T('Start Date:')"
                  :invalid-feedback="T('invalid start date')"
                  :state="!$v.patient.StartDate.$error">
                  <date-picker class="form-control" id="patientStartDate" v-model="$v.patient.StartDate.$model" :lang="Language()" :placeholder="T('Select Date')"></date-picker>
                  
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-cols="3">
                  <b-form-checkbox switch
                                   v-model="$v.patient.AcceptNotification.$model"
                                   name="check-button">
                    {{T('Notification')}} 
                    <span v-if="patient.AcceptNotification == true">{{T('On')}}</span>
                    <span v-if="patient.AcceptNotification == false">{{T('Off')}}</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-cols="3"
                              label-align="right"
                              label-for="patientEmail"
                              :label="T('Email:')"
                              :invalid-feedback="T('invalid email')"
                              :state="!$v.patient.Email.$error">
                  <b-form-input type="email" id="patientEmail" v-model="$v.patient.Email.$model" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-cols="3"
                  label-for="patientPhone"
                  label-align="right"
                  :label="T('Phone:')"
                  :invalid-feedback="T('invalid phone number')"
                  :state="!$v.patient.Phone.$error">
                  <vue-phone-number-input id="patientPhone" v-model="phone" @update="onUpdatePhone" :translations="phoneNumberInputTranslations()" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label-cols="3"
                  label-align="right"
                  label-for="patientTz"
                  :label="T('Timezone:')"
                  :invalid-feedback="T('invalid timezone')"
                  :state="!$v.patient.Timezone.$error">
                  <b-form-input id="patientTz" type="text" v-model="$v.patient.Timezone.$model" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label-cols="3">
                  <b-form-select @change="onTzSelected" :options="allTimezone" :select-size="2"></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab :title="T('Contact Information')">
          <contact-information v-model="patient" />
        </b-tab>
        <b-tab :title="T('Notification')">
          <notification v-model="patient" class=""/>
        </b-tab>
      </b-tabs>
    </b-card>
    <div slot="modal-footer" class="w-100">
      <b-row >
        <b-col> 
          <b-btn class="ml-2" variant="danger" @click="onRemove">{{T('Remove Patient')}}</b-btn>
        </b-col>
        <b-col md="auto"> 
          <b-btn class="mx-2" variant="primary" @click="onSave">{{T('Save')}}</b-btn>
          <b-btn class="mx-2" variant="secondary" @click="onCancel">{{T('Cancel')}}</b-btn>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { phonenumber } from '@/validators';
import DatePicker from 'vue2-datepicker'
import ContactInformation from './ContactInformation';
import Notification from './Notification';

import {  getNotficationType, messageTypeExist, removeMessageTypeFromList, addNotification } from "@/lib/notification";
import { getAvailableTimeZone } from '@/lib/timezone.js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import { languageList } from '@/lib/translator.js';

export default {
  components: { DatePicker, VuePhoneNumberInput, ContactInformation, Notification },
  props: {
    id: {
      required: true,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      patient: Object.assign({}, this.value),
      phone: this.value.Phone,
    };
  },
  validations: {
    patient: {
      Name: {
        required
      },
      Language: {
        required
      },
      StartDate: {
        required
      },
      AcceptNotification: {
        required
      },
      Phone: {
        phonenumber
      },
      Email: {
        email
      },
      Timezone: {
        required
      },
    },
  },
  computed: {
    reminders() {
      return this.patient.Reminders || [];
      // return r.sort(function(a, b) {return a.Offset < b.Offset});
    },
    recipients() {
      return this.patient.Recipients || [];
    },
    allTimezone() {
      return getAvailableTimeZone()
    },
    availableLanguage() {
      return languageList;
    },
    languageString() {
      let lang = this.availableLanguage.find(l => l.value === this.$v.patient.Language.$model);
      return (lang && lang.text) || "";
    }
  },
  methods: {
    languageChangeConfirmationHandler(name) {
      if (name.trigger == "cancel") {
        this.$bvModal.hide('patientSettingDialog');
        return
      }

      if (name.trigger == "Yes") {
        getNotficationType().filter(i => i != "Monitoring Only").forEach(r => {
          if (messageTypeExist(r, this.patient.Reminders)) {
            this.patient.Reminders = removeMessageTypeFromList(r, [...this.patient.Reminders]);
            addNotification(this.patient, r);
          }
        });
      }
      this.$emit('save-patient', this.patient);
      this.$bvModal.hide('patientSettingDialog');
    },
    onSave(evt) {
      evt.preventDefault()
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let self = this;
        this.$bvModal.msgBoxConfirm(this.T('Are you sure you want to save all changes to this patient?'), {
          okTitle: this.T('OK'),
          cancelTitle: this.T('Cancel'),
        }).then(value => {
            if (value) {
              if (self.$v.patient.Language.$model != self.value.Language) {
                self.$bvModal.show('languageChangeConfirmation');
              } else {
                self.$emit('save-patient', self.patient);
                self.$bvModal.hide('patientSettingDialog');
              }
            }
        });
      }
    },
    onRemove() {
      this.$bvModal.msgBoxConfirm(this.T('Are you sure you would like to remove this patient?'), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
          if (value) {
            this.$emit('remove-patient', this.patient);
          }
        });
    },
    onCancel() {
      this.patient = Object.assign({}, this.value);
      this.$refs.patientSettingDialog.hide();
    },
    onUpdatePhone(phone) {
      this.$v.patient.Phone.$model = phone.formattedNumber;
    },
    onTzSelected(val) {
      this.patient.Timezone = val;
    }
  },
  watch: {
    value(val) {
      this.patient = Object.assign({}, val);
      this.phone = val.Phone;
    },
  },
}
</script>

<style lang="scss">
.tabs {
    min-height: 500px;
}

.dialog {
    max-height: 700px;
}
</style>
