<template>
<b-card v-show="show" :title="T(title)" class="dialog">
  <b-card-text>
<b-container fluid class="body">
  <b-row v-if="fullOptionsList.length != 0" class="py-3">
    <b-col md="auto">
      <b-form-select v-model="selected" :select-size="3" multiple>
        <option v-for="(o, idx) in fullOptionsList" :key="idx" :value="o">
          <b-container>
            <b-row class="justify-content-md-left">
              <b-col sm="auto">{{o.Name}}</b-col>
              <b-col sm="auto" v-if="o.Email">{{T('Email')}}: {{o.Email}}</b-col>
              <b-col sm="auto" v-if="o.Phone">{{T('Phone')}}: {{o.Phone}}</b-col>
            </b-row>
          </b-container>
        </option>
      </b-form-select>
    </b-col>
    <b-col md="auto">
      <b-btn class="m-2" @click="onAddRecipient">{{T('Add')}}</b-btn>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
      <b-list-group style="">
        <b-list-group-item  v-for="(r, idx) in recipients" :key="idx">
          <b-container fluid class="text-left">
            <b-row class="text-left">
              <b-col sm cols="3">
                <span class="align-baseline"><b>{{T('Name')}}:</b> {{ r.Name }}</span>
              </b-col>
              <b-col sm cols="5">
                <span class="align-baseline" v-if="r.Email"><b>{{T('Email')}}:</b> {{ r.Email }}</span>
                <span class="align-baseline" v-if="r.Phone"><b>{{T('Phone')}}:</b> {{ r.Phone }}</span>
              </b-col>
              <b-col cols="auto"> 
                <b-btn variant="danger" size="sm" @click="onRemove(r)">{{T('Remove')}}</b-btn>
              </b-col>
            </b-row>
          </b-container>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</b-container>
</b-card-text>
  <template #footer>
     <b-row no-gutters align-h="end">
       <b-col md="auto">
         <b-btn class="m-2" @click="onCancel" variant="secondary" >{{T('Cancel')}}</b-btn>
         <b-btn class="m-2" @click="onOk" variant="primary" >{{T('Ok')}}</b-btn>
       </b-col>
     </b-row>
   </template>
</b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    options: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Manage Recipient",
    },
  },
  data() {
    return {
      selected: [],
      localOptions: [],
      recipients: [],
    };
  },
  computed: {
    fullOptionsList() {
      let list = []
      this.options.forEach(function (item) {
        if (item.Email && item.Email != '') {
          list.push({Name: item.Name, Email: item.Email});
        }
        if (item.Phone && item.Phone != '') {
          list.push({Name: item.Name, Phone: item.Phone});
        }
      });
      return list;
    },
  },
  methods: {
    onAddRecipient() {
      let _vm = this;
      this.selected.forEach(function(i) {
        _vm.recipients.unshift(i)
      });
    },
    onCancel() {
      this.$emit('cancel');
    },
    onRemove(recipient) {
      let idx = this.recipients.indexOf(recipient);
      this.recipients.splice(idx, 1);
    },
    onOk() {
      let r = Object.assign({}, this.value, {Recipients: this.recipients})
      this.$emit('input', r);
      this.$emit('ok', r);
    }
  },
  watch: {
    options(newVal) {
      this.localOptions = newVal;
    },
    value(val) {
      this.recipients = [...val.Recipients];
    },
  },
}
</script>

<style lang="scss">
.dialog {
    max-height: 410px;
    .body {
       height: 250px;
       overflow-y: scroll
    }
}
            
</style>


