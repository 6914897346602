<template>
<b-container> 
  <b-container v-show="!(showReminderDialog || showRecipientDialog)"> 
    <b-row>
      <b-col>
        <b-card no-body class="dialog">
          <div v-for="(r, ridx) in remindersOnPage" :key="ridx">
            <b-container class="notification-list">
              <b-row class="my-2">
                <b-col>
                  <b>{{r.Offset}} {{T('minutes to schedule time:')}}</b>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-alert show variant="info">
                    {{r.Message}}
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                <b-list-group flush class="recipients-list">
                  <b-list-group-item v-for="(item, idx) in r.Recipients" :key="idx">
                    <small class="mb-1">
                    {{T('Notify')}} <b>{{ item.Name | defaultValue('(n/a)') }}</b> {{T('Via')}}
                    <b v-if="item.Phone">{{T('Sms')}}: {{ item.Phone }}</b>
                    <b v-if="item.Email">{{T('Email')}}: {{ item.Email }}</b>
                    </small>
                  </b-list-group-item>
                </b-list-group>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <b-col md="auto">
                  <b-button-group class="mx-1">
                  <b-btn @click="onManageRecipients(r)">{{T('Manage recipients')}}</b-btn>
                  <b-btn @click="onEditReminder(r)">{{T('Edit')}}</b-btn>
                  <b-btn @click="onRemoveReminder(r)">{{T('Remove')}}</b-btn>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-1">
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
      <b-col md="auto" class="my-1">
        <b-btn class="mx-2" @click="onEditNewReminder" >{{T('Add Reminder')}}</b-btn>
      </b-col>
    </b-row>
  </b-container>
  <edit-reminder :show="showReminderDialog"
                 :title="T('Edit Reminder')"
                 v-model="reminder"
                 @ok="onSaveReminder"
                 @cancel="showReminderDialog = false" />
  <manage-recipients :show="showRecipientDialog"
                     :title="T('Manage Recipient')"
                     v-model="reminder"
                     :options="recipients"
                     @ok="onSaveRecipients"
                     @cancel="showRecipientDialog = false" />
</b-container>
</template>

<script>
import Vue from "vue";
import EditReminder from './EditReminder';
import ManageRecipients from './ManageRecipients';
import { Reminder } from '../../models';

export default {
  components: { EditReminder, ManageRecipients },
  props: {
    value: {
      required: true,
    },
  },
  data () {
    return {
      reminder: new Reminder(),
      selectedIndex: -1,
      showRecipientDialog: false,
      showReminderDialog: false,
      perPage: 1,
      currentPage: 1,
      fields: [
        { key: 'cell', label: ''},
      ],
    };
  },
  computed: {
    totalRows() {
      return this.reminders.length;
    },
    remindersOnPage() {
      let idx = (this.currentPage - 1) * this.perPage;
      if (idx > this.reminders.length) {
        idx = this.reminders.length - 1;
      }
      return this.reminders.slice(idx, idx + this.perPage);
    },
    reminders() {
      let r = [];
      if (this.value && Array.isArray(this.value.Reminders)) {
        r.push(...this.value.Reminders);
      }
      return r;
    },
    recipients() {
      if (this.value.Recipients) {
        let l = [...this.value.Recipients]
        if (this.value.Email) {
          l.unshift({Name: this.value.Name, Email: this.value.Email});
        }
        if (this.value.Phone) {
          l.unshift({Name: this.value.Name, Phone: this.value.Phone});
        }
        return l;
      }
      return [];
    }
  },
  methods: {
    onManageRecipients(reminder) {
      this.selectedIndex = this.reminders.indexOf(reminder);
      this.reminder = Object.assign({}, reminder);
      this.showRecipientDialog = true;
    },
    onEditNewReminder() {
      this.onEditReminder(new Reminder());
    },
    onEditReminder(reminder) {
      this.selectedIndex = this.reminders.indexOf(reminder);
      this.reminder = Object.assign({}, reminder);
      this.showReminderDialog = true;
    },
    onRemoveReminder(reminder) {
      let idx = this.reminders.indexOf(reminder);
      if (idx > -1) {
        this.reminders.splice(idx, 1);
        let p = Object.assign({}, this.value, {Reminders: this.reminders});
        this.$emit('input', p)
      }
    },
    onSaveReminder(reminder) {
      this.SaveReminder(reminder);
      this.showReminderDialog = false;
    },
    onSaveRecipients(reminder) {
      this.SaveReminder(reminder);
      this.showRecipientDialog = false;
    },
    SaveReminder(reminder) {
      if (this.selectedIndex > -1) {
        Vue.set(this.reminders, this.selectedIndex, reminder);
      } else {
        this.reminders.unshift(reminder);
      }

      let p = Object.assign({}, this.value, {Reminders: this.reminders});
      this.$emit('input', p)
    }
  },
  watch: {
    reminders(val) {
      if (val.length < this.currentPage * this.perPage) {
        this.currentPage = Math.round(val.length / this.perPage);
      }
    },
  },
}
</script>

<style lang="scss">
.dialog {
    max-height: 410px;
    .notification-list {
        height: 350px;
        overflow-y: scroll;
    }
    .recipients-list {
        max-height: 200px;
        overflow-y: scroll;
    }
}
            

</style>


