<template>
  <b-modal id="changePasswordDialog" :title="T('Change Password')" ref="changePasswordDialog" :ok-title="T('Update')" :cancel-title="T('Cancel')" @ok="onUpdate">
    <b-container>
      <b-row>
        <b-col>
          <b-form-group
            :label="T('Current password')"
            label-for="currentPassword">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock"></i>
              </b-input-group-text>
              <b-form-input type="password" v-model="$v.password.$model" />
            </b-input-group>
            <validation-error :validation="$v.password" />
          </b-form-group>
          <b-form-group
            :label="T('New password')"
            label-for="password">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock"></i>
              </b-input-group-text>
              <b-form-input type="password" v-model="newPassword" />
            </b-input-group>
          </b-form-group>
          <b-form-group
            :label="T('Confirm your password')"
            label-for="confirmPassword">
            <b-input-group>
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock"></i>
              </b-input-group-text>
              <b-form-input type="password" v-model="$v.confirmPassword.$model" />
            </b-input-group>
            <validation-error :validation="$v.confirmPassword" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ValidationError from '../components/ValidationError';

export default {
  components: { ValidationError },
  data() {
    return {
      password: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  validations: {
    password: {
      required,
    },
    confirmPassword: {
      required,
      match: function(value) {
        return value === this.newPassword;
      }
    },
  },
  methods: {
    onUpdate(evt) {
      evt.preventDefault();
      this.$v.$touch();
      let _vm = this;
      if (!this.$v.$invalid) {
        this.$store.dispatch('changePassword', {oldPassword: this.password, newPassword: this.newPassword})
          .then(() => {
            _vm.$refs.changePasswordDialog.hide();
            _vm.$v.$reset();
          });
      }
    }
  },
}
</script>
