<template>
<b-container fluid>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-container fluid>
          <b-row>
            <b-col cols="2">{{T('Device')}}</b-col>
            <b-col cols="3">{{T('Description')}}</b-col>
            <b-col cols="1">{{T('Video')}}</b-col>
            <b-col cols="2">{{T('Start Date')}}</b-col>
            <b-col cols="2">{{T('End Date')}}</b-col>
            <b-col cols="1">{{T('Rating')}}</b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
    <b-list-group style="height: 200px; overflow-y: scroll">
      <b-list-group-item v-for="s in schedulesOnPage"
                         :key="s.ID"
                         href="#"
                         :active="s.ID == selectedSchedule.ID"
                         event="click"
                         @click="onClick(s)"
                         class="px-0"
                         data-toggle="tooltip" data-placement="top" :title="`${T('Card ID')}: ${s.CardID}`">
        <b-container fluid>
          <b-row>
            <b-col cols="2" >
              {{s.CardID | device }}
            </b-col>
            <b-col cols="3" >{{s.Description | defaultValue("--")}} </b-col>
            <b-col cols="1">
              <b-btn size="sm" variant="info" v-show="s.VideoURLs" @click="showVideoDialog(s.VideoURLs)">
                <i class="fas fa-play"></i>
              </b-btn>
            </b-col>
            <b-col cols="2">{{s.StartDate | formatDate }}</b-col>
            <b-col cols="2">{{s.EndDate | formatDate }}</b-col>
            <b-col cols="1">
              {{s.Score | round }}%
            </b-col>
            <b-col cols="1">
              <span class="lock-icon" v-show="scheduleAssigned"><i class="fas fa-link"></i></span>
              <b-btn size="sm"
                     variant="info"
                     v-show="s.CardID.match(/^MEV/)"
                     @click="showDeviceInfo">
                <i class="fas fa-info"></i>
              </b-btn>
            </b-col>
          </b-row>
        </b-container>
      </b-list-group-item>
    </b-list-group>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col>
        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
      </b-col>
      <b-col md="auto" class="py-2">
        <b-button-group>
          <b-btn :disabled="!buttonActive" v-b-modal.doseSchedulerDialog>{{T('Add Dose')}}</b-btn>
          <b-btn :disabled="!buttonActive" v-b-modal.youtubeVideoCodeDialog>{{T('Change Video')}}</b-btn>
          <b-btn :disabled="!buttonActive || scheduleAssigned" v-b-modal.assignCardDialog>{{T('Assign Card')}}</b-btn>
          <b-btn :disabled="!buttonActive" v-b-modal.editScheduleDateDialog>{{T('Clone')}}</b-btn>
          <b-btn :disabled="!buttonActive" variant="danger" @click="onRemoveSchedule">{{T('Remove')}}</b-btn>
        </b-button-group>
      </b-col>
    </b-row>
  </b-container>
  <vue-cal hide-view-selector
           default-view="week"
           :time-from="0 * 60"
           :time-to="24 * 60"
           :min-cell-width="45"
           :time-cell-height="65"
           time-format="hh:mm am"
           date-format="dddd D MMMM YYYY"
           :locale="Language()"
           no-event-overlaps
           :events="events"
           :disable-views="['years', 'year', 'month', 'day']"
           :on-event-click="onEventClick">
    <template v-slot:event="{ event }">
      <div :id="event.dose.ID" >
      <div class="vuecal__event-title" v-html="T(event.title)"></div>
      <small class="vuecal__event-time">
        <div>{{event.dose.Title}}</div>
        <div>{{ event.start.formatTime("hh:mm am") }} : {{ event.end.formatTime("hh:mm am") }}</div>
      </small>
      </div>
      <b-tooltip v-if="event.dose.BlisterIndex != 0" :target="event.dose.ID" triggers="hover">
        <div>{{event.blister}}</div>
        <div>{{event.dose.Title}}</div>
        <div v-show="event.taken">{{T('taken')}} : {{event.taken}}</div>
      </b-tooltip>
    </template>
  </vue-cal>

  <dose-schedule-editor id="doseSchedulerDialog"
                        :startDate="selectedSchedule.StartDate"
                        :endDate="selectedSchedule.EndDate"
                        :timezone="timezone"
                        @save="onCreateDoses" />

  <schedule-youtube-video-code-editor id="youtubeVideoCodeDialog"
                           v-model="selectedSchedule"
                           @save="onSaveYoutubeVideoCode" />

  <assign-card id="assignCardDialog"
               @submit="onAssignCard" />

  <schedule-date-editor id="editScheduleDateDialog"
                        :timezone="timezone"
                        v-model="selectedSchedule"
                        @save="onCloneSchedule" />

  <dose-event-editor id="doseEditorDialog"
                     :dose="selectedDose"
                     :startDate="selectedSchedule.StartDate"
                     :endDate="selectedSchedule.EndDate"
                     :timezone="timezone"
                     @remove="onRemoveDose"
                     @save="onSaveDose"
                     @multisave="onMultiSaveDoses" />
 <video-player-dialog  :videourls="video" />
  <b-modal id="cardInfo" title="" ok-only>
    <template slot="modal-title">
      {{T('Info for device')}}: {{selectedSchedule.CardID | device}}
    </template>
    <div v-if="deviceInfo != {}">
      <div>{{T('Battery Status')}}: {{deviceInfo.battery_percentage}}%</div>
      <div>{{T('Signal Strength')}}: {{deviceInfo.signal_strength | powerlevel }}%</div>
    </div>
  </b-modal>
</b-container>
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es.js';
import moment from 'moment-timezone';

import AssignCard from '../components/AssignCard';
import DoseScheduleEditor from '../components/DoseScheduleEditor';
import ScheduleYoutubeVideoCodeEditor from '../components/ScheduleYoutubeVideoCodeEditor';
import ScheduleDateEditor from '../components/ScheduleDateEditor';
import DoseEventEditor from '../components/DoseEventEditor';
import VideoPlayerDialog from '@/components/VideoPlayerDialog';

import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

function getStatus(dose) {
  let e = moment(dose.Intake).add({hours: dose.Range, seconds: 1});
  let r = dose.Range || 1;
  let s = moment(dose.Intake).subtract({hours: r, seconds: 1});
  if (dose.Taken && dose.Status == 'Open') {
    return (moment(dose.Taken).isBefore(e) && moment(dose.Taken).isAfter(s)) ? 'Ok' : 'Late';
  }
  let now = moment();
  return now.isAfter(e) ? 'Overdue' : 'Close';
}

function getDoseDifference(origDose, newDose) {
  let label = (origDose.Label != newDose.Label) ? newDose.Label : undefined;
  let title = (origDose.Title != newDose.Title) ? newDose.Title : undefined;
  let range = newDose.Range - origDose.Range;
  let intake = moment(newDose.Intake).diff(origDose.Intake);
  return {Label: label, Title: title, Range: range, Intake: intake};
}

function getClock(t) {
  let mt = moment(t)
  return {Hours: mt.hour(), Minutes: mt.minute()}
}

export default {
  components: { VueCal, AssignCard, ScheduleYoutubeVideoCodeEditor, ScheduleDateEditor, DoseScheduleEditor, DoseEventEditor, VideoPlayerDialog },
  model: {
    prop: 'patientID'
  },
  props: {
    patientID: {
      required: true
    }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 4,
      deviceInfoLoading: false,
      deviceInfo: {},
      video: "",
    }
  },
  computed: {
    ...mapState('patients', ['selectedPatient']),
    ...mapGetters('patients', ['findPatient']),
    ...mapState('schedules', ['selectedSchedule', 'schedules']),
    ...mapState('doses', ['doses', 'selectedDose']),
    schedulesOnPage() {
      let idx = (this.currentPage - 1) * this.perPage;
      return this.schedules.slice(idx, idx + this.perPage);
    },
    totalRows() {
      return this.schedules.length;
    },
    scheduleAssigned() {
      return this.selectedSchedule.CardID.length != 0;
    },
    buttonActive() {
      return this.totalRows != 0;
    },
    timezone() {
      let p = this.selectedPatient;
      if (!p) {
        p = this.findPatient(this.patientID)
      }
      return (p && p.Timezone)  || moment.tz.guess();
    },
    events() {
      return this.doses.map((dose) => {
        let e = moment.tz(dose.Intake, this.timezone).add(dose.Range, 'h').format('YYYY-MM-DD HH:mm');
        let r = dose.Range || 1
        let s = moment.tz(dose.Intake, this.timezone).subtract(r, 'h').format('YYYY-MM-DD HH:mm');
        let taken = (dose.Status == 'Open') ? moment.tz(dose.Taken, this.timezone).format('YYYY-MM-DD HH:mm') : "";
        let blisterIdx = dose.BlisterIndex == 0 ? this.T("Blister") : `${this.T('Blister')}  ${dose.BlisterIndex}`;
        let title = (dose.Status == 'Close') ? blisterIdx : `${this.T('taken')} @ ${moment(dose.Taken).format("HH:mm")}`;

        let status = getStatus(dose);

        return {
          dose: dose,
          taken: taken,
          blister: blisterIdx,
          start: s,
          end: e,
          title: title,
          class: status,
        };
      });
    },
  },
  methods: {
    ...mapMutations('schedules', ['setSelectedSchedule']),
    ...mapActions('schedules', ['storeSchedule', 'removeSchedule', 'assignCard', 'cloneSchedule', 'getDeviceInfo']),
    ...mapMutations('doses', ['setSelectedDose']),
    ...mapActions('doses', ['createDose', 'updateDose', 'updateMultiDoses', 'removeDose']),
    onClick: function(schedule) {
      this.setSelectedSchedule(schedule);
    },
    onAssignCard: function(card) {
      let msg = Object.assign({scheduleid: this.selectedSchedule.ID}, card);
      this.assignCard(msg);
    },
    onCloneSchedule: function(schedule) {
      this.cloneSchedule(schedule);
    },
    onRemoveSchedule: function() {
      this.$bvModal.msgBoxConfirm(this.T("Are you sure you would like to remove this schedule?"), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
          if (value) {
            if (this.removeSchedule(this.selectedSchedule)) {
              this.setSelectedSchedule(this.schedules[0] || {});
            }
          }
        });
    },
    onEventClick: function(event, e) {
      let dose = event.dose;
      // SjB you can always edit a dose
      this.setSelectedDose(dose);
      this.$bvModal.show('doseEditorDialog');
      e.stopPropagation();
    },
    onCreateDoses: function(doses) {
      let s = this.selectedSchedule;
      let _vm = this;
      doses.forEach(function(dose) {
        dose.PatientID = s.PatientID;
        dose.ScheduleID = s.ID;
        _vm.createDose(dose);
      });
    },
    onSaveDose: function(dose) {
      this.updateDose(dose);
    },
    onMultiSaveDoses: function(dose) {
      let cmpClk= getClock(this.selectedDose.Intake)
      let list = this.doses.filter(function(d) {
        let clk = getClock(d.Intake)
        return ((clk.Hours == cmpClk.Hours) && (clk.Minutes == cmpClk.Minutes))
      });

      let adjust = getDoseDifference(this.selectedDose, dose);
      let newDoses = list.map(function(d) {
        let o = Object.assign({}, d)
        if (adjust.Title) {
          o.Title = adjust.Title;
        }
        if (adjust.Label) {
          o.Label = adjust.Label;
        }

        o.Intake = moment(o.Intake).add(moment.duration(adjust.Intake))
        if (adjust.Range != 0) {
          o.Range = dose.Range
        }
        return o
      })
      this.updateMultiDoses(newDoses)
    },
    onRemoveDose: function() {
      this.$bvModal.msgBoxConfirm(this.T("Are you sure you would like to erase this Dose."), {
        okTitle: this.T('OK'),
        cancelTitle: this.T('Cancel'),
      }).then(value => {
          if (value) {
            this.removeDose(this.selectedDose);
            this.$bvModal.hide('doseEditorDialog');
          }
        })
    },
    onSaveYoutubeVideoCode: function (code) {
      let s = Object.assign({}, this.selectedSchedule);
      s.VideoURLs = [code];
      this.storeSchedule(s);
    },
    showDeviceInfo: function() {
      this.deviceInfoLoading = true;
      this.deviceInfo = {};
      this.getDeviceInfo(this.selectedSchedule).then(post => {
        if (post !== null) {
          this.deviceInfo = Object.assign({}, post);
          this.$root.$emit('bv::show::modal', 'cardInfo');
        }
        this.deviceInfoLoading = false;
      });
    },
    showVideoDialog: function(video) {
      this.video = video
      this.$bvModal.show("videoPlayerDialog")
    }
  },
  watch: {
    selectedSchedule: function(val) {
      this.$store.dispatch('doses/getDoses', val.ID)
    },
    patientID: function(val) {
      this.$store.dispatch('schedules/getSchedules', val);
    },
  },
  created() {
    this.$store.dispatch('schedules/getSchedules', this.patientID);
  },
  filters: {
    device: function(value) {
      if (!value) return ''
      let parts = value.split(".")
      if (!parts[2])
        return value.substring(value.length - 6, value.length)
      return parts[2]
    },
    round: function(value) {
      if (!isNaN(parseFloat(value)) && isFinite(value))
        return Math.round(value * 100) / 100.0
      return '';
    },
    powerlevel: function(value) {
      return Math.abs(value)
    },
  },
}
</script>

<style>

.lock-icon {
  margin: 2px 5px;
}

.vuecal__time-column { width: 4em; }
.vuecal__menu, .vuecal__cell-events-count { background-color: #48525A; }
.vuecal__title-bar {background-color: #e5e5e5;}

.vuecal__event {cursor: pointer;}

.vuecal__event.Close{
  background: #007bff;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Overdue {
  background: #FF0000;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Ok{
  background: #008000;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.vuecal__event.Late{
  background: #ffd700;
  color: #fff;
  justify-content: center;
  align-items: center;
}


.vuecal__event.Close .vuecal__event-time {align-items: center; font-size: .75em;}
.vuecal__event.Open .vuecal__event-time {align-items: center; font-size: .75em;}

.mx-input-append {
    padding: 0px;
}

.mx-datepicker {
    display: block;
    line-height: 1.5;
}

.mx-input {
    padding: 0px;
    border: none;
    box-shadow: none;
    height: 24px;
}
</style>
